export const defaultOptions = {
  debug: true,

  tagManager: true,

  hostsToExcludeWithHash: false,

  // cookie
  cookieServices: {
    mediaCookies: [
      {
        id: 'youtube',
        title: 'Youtube'
      },
      {
        id: 'vimeo',
        title: 'Vimeo'
      },
      {
        id: 'issuu',
        title: 'Issuu',
        // optional
        link: 'https://issuu.com/legal/privacy',
        linkText: 'Datenschutz lesen'
      }
    ],
    trackingCookies: [
      {
        id: 'googleAnalytics',
        title: 'Google Analytics'
      },
      {
        id: 'facebook',
        title: 'Facebook'
      }
    ],
    marketingCookies: null,
    requiredCookies: [
      {
        id: 'required',
        title: 'Required test'
      }
    ],
  },

  cookieDomains: [
    // local
    '127.0.0.1',
    'localhost',

    // example
    '.domain.com',
    'submain.domain.com',
    'submain2.domain.com'
  ],

  pagesToExclude: [
    '/de/impressum',
    '/en/impressum',
    '/en/datenschutz',
    '/de/datenschutz',
  ],

  hostsToExclude: [
    // '127.0.0.1:8000',
  ],

  // if have multiples subdomains add the domain with a .(dot) at the beginning
  mainDomain: '', // set different domain for example '.mir.de' to include all subdomains as well

  // translations
  translations: {
    introduction: {
      title: `Wir verwenden Cookies`,
      text: `Mit der Auswahl “Akzeptiere Tracking” erlaubst Du adidas die Verwendung von Cookies, Pixeln, Tags und ähnlichen Technologien. Wir nutzen diese Technologien, um Deine Geräte- und Browsereinstellungen zu erfahren, damit wir Deine Aktivität <a href="/de/datenschutz">nachvollziehen können</a>.`,
    },
    settings: {
      permissions: {
        requiredCookies: {
          title: `Erforderlich`,
          text: `Wir nutzen Cookies, um die Bedienung der Website zu ermöglichen und sicherzustellen, dass bestimmte Funktionen ordnungsgemäß funktionieren, wie die Möglichkeit, sich anzumelden oder ein Produkt in den Warenkorb zu legen. Dieses Tracking ist immer aktiviert, da Du sonst die Website nicht sehen oder online einkaufen kannst.`,
        },
        trackingCookies: {
          title: `Tracking`,
          text: `Mit Ihrer Zustimmung erlauben Sie das Setzen von Cookies zum Zwecke der Reichweitenmessung und zur Nutzungsanalyse und helfen uns zu verstehen, wie Besucher mit unserer Website interagieren, indem Informationen anonym gesammelt und analysiert werden`,
        },
        marketingCookies: {
          title: `Marketing`,
          text: `Bei der Nutzung dieser Dienste werden personenbezogene Daten, z. B. Ihre IP-Adresse, an die jeweiligen Anbieter (mit Sitz außerhalb der EU, z. B. in den USA) übermittelt. Ihre Zustimmung können Sie jederzeit durch Mitteilung widerrufen.`,
        },
        mediaCookies: {
          title: `Externe Medien`,
          text: `Bei der Nutzung dieser Dienste werden personenbezogene Daten, z. B. Ihre IP-Adresse, an die jeweiligen Anbieter (mit Sitz außerhalb der EU, z. B. in den USA) übermittelt. Ihre Zustimmung können Sie jederzeit durch Mitteilung widerrufen. Für die USA bestehen weder ein Angemessenheitsbeschluss der EU-Kommission noch geeignete Garantien, die ein angemessenes Datenschutzniveau sicherstellen. Daher besteht insbesondere das Risiko, dass Betroffenenrechte nicht wirksam durchgesetzt werden können oder Sicherheitsbehörden die Herausgabe von Daten verlangen.`,
        },
      },
    },
    buttons: {
      accept: `Alle Akzeptieren`,
      deleteCookies: `Cookies löschen`,
      saveSettings: `Einstellungen speichern`,
    },
  },

  // links come from the version 2.0
  links: {
    imprint: {
      link: '/de/impressum',
      text: 'Impressum'
    },
    copyright: {
      link: '/de/datenschutz',
      text: 'Datenschutz'
    }
  },

  // style come from the version 2.0
  styles: {
    // .modal-wrapper
    modal: {
      backgroundColor: '#fff',
      fontFamily: 'Arial, Helvetica, sans-serif'
    },

    // .introduction__title
    title: {
      // fontFamily: 'sans-serif',
      // color: '#313131',
      // fontSize: '28px'
    },

    buttons: {
      // .cookie-manager__button.accept-cookie-settings
      acceptAll: {
        backgroundColor: '#5cc230',
        color: '#ffffff',
        fontSize: '16px'
      },
      // .cookie-manager__button.save-cookie-settings
      saveSettings: {
        backgroundColor: '#d8dede',
        color: '#464646',
        // fontFamily: 'serif',
        fontSize: '16px'
      }
    },

    // .checkbox-toggle__title
    checkboxText: {
      // fontSize: '20px'
    },

    // .checkbox-toggle--bold .checkbox-toggle__title
    checkboxTextOption: {
      // fontSize: '20px'
    },

    // .cookie-manager__link
    link: {
      color: '#8f8c88'
    }

  }
}
